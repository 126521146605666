<template>
  <div class="rights-page">
    <TopPanel back backText="Вернуться назад" @back-click="$router.back()" />

    <div v-if="isLoading"><Loader /></div>
    <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
    <div v-else>

      <div class="rights-tabs">
        <div :class="{ active: activeTab === 1 }" @click="activeTab = 1">Сотрудники</div>
        <div :class="{ active: activeTab === 2 }" @click="activeTab = 2">Группы</div>
        <div :class="{ active: activeTab === 0 }" @click="activeTab = 0">Роли</div>
      </div>

      <div v-if="activeTab === 0">

        <Block title="Роли и права" desc="Создавайте роли и назначайте им права">

          <b-table-simple id="rights_table">
            <b-thead>
              <b-tr>
                <b-th><a v-if="!isRoleAdding" href="#" @click.prevent="isRoleAdding = true">Добавить роль</a></b-th>
                <b-th v-if="isRoleAdding">
                  <div class="role-title-wrap role-title-wrap-nodropdown">
                    <div class="role-title-rename">
                      <b-form-input
                        v-model.lazy="newRoleTitle"
                        placeholder="Введите название роли"
                        trim
                        required
                        autofocus
                        @focusout="isRoleAdding = false"
                        @keydown.enter="addRole"
                      />
                    </div>
                  </div>
                </b-th>
                <b-th v-for="role in roles" :key="role.id">
                  <div class="role-title-wrap" :class="{ 'role-title-wrap-nodropdown': role.id === 'admin' || role.isRenaming }">
                    <div class="role-title-rename" v-if="role.isRenaming">
                      <b-form-input
                        v-model.lazy="role.title"
                        placeholder="Введите название роли"
                        trim
                        required
                        autofocus
                        @focusout="toggleRoleRenaming(role.id)"
                        @keydown.enter="toggleRoleRenaming(role.id)"
                      />
                    </div>
                    <div class="role-title" v-else>
                      <span @click="if (role.id !== 'admin') toggleRoleRenaming(role.id)">{{ role.title }}</span>
                      <b-dropdown v-if="role.id !== 'admin'" no-caret dropleft size="sm" toggle-class="btn-role-dropdown">
                        <template #button-content>
                          <svg width="2" height="10" viewBox="0 0 2 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="2" height="2" fill="#1F2641"/>
                            <rect y="4" width="2" height="2" fill="#1F2641"/>
                            <rect y="8" width="2" height="2" fill="#1F2641"/>
                          </svg>
                        </template>
                        <b-dropdown-item @click="toggleRoleRenaming(role.id)">Переименовать</b-dropdown-item>
                        <b-dropdown-item v-if="!role.system" @click="removeRole(role.id)">Удалить</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="role-users">
                    <a href="#" :id="'role-users-' + role.id">{{ users.filter(u => u.roles.includes(role.id)).length | pluralize(['сотрудник', 'сотрудника', 'сотрудников']) }}</a>
                    <b-popover :target="'role-users-' + role.id" triggers="hover" placement="bottom" custom-class="role-users-dropdown">
                      <Avatar class="mb-2" :photo="user.avatar" :title="user.name" v-for="user in users.filter(u => u.roles.includes(role.id))" :key="user.id" />
                    </b-popover>
                  </div>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="perm in perms" :key="perm.id" :class="{ parent: perm.is_parent }">
                <b-td>{{ perm.title }}</b-td>
                <b-td v-if="isRoleAdding">
                  <Switchbox disabled />
                </b-td>
                <b-td v-for="role in roles" :key="role.id">
                  <Switchbox :disabled="role.id === 'admin'" :value="role.perms.includes(perm.id)" @change="togglePerm(role.id, perm.id)" />
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

        </Block>
      </div>
      <div v-else-if="activeTab === 1">
        <Block class="rights-search-users" mb10>
          <b-form-input placeholder="Поиск по сотрудникам" v-model="searchQuery" />
        </Block>
        <Block>
          <h1>Сотрудники</h1>

          <b-form class="rights-new-user" inline>
            <b-form-group label="Сотрудник">
              <EntitySelect2
                url="user"
                v-model="selectedUser"
                label="name"
                placeholder="Выберите сотрудника"
                state
              />
            </b-form-group>
            <b-form-group label="Роль">
              <v-select v-model="selectedRole" :options="roles" label="title" :reduce="el => el.id" placeholder="Выберите роль">
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes"><SpriteIcon icon="check" /></span>
                </template>
              </v-select>
            </b-form-group>
            <div class="align-self-end"><button class="btn btn-primary" @click.prevent="addUser">Добавить сотрудника</button></div>
            <Alert v-if="isUserRoleWarning" icon="warning">Такая роль уже назначена</Alert>
          </b-form>

          <b-table-simple class="rights_users_table">
            <b-tr v-for="user in users.filter(u => u.roles.length && (!searchQuery || u.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1))"
              :key="user.id">
              <b-td width="370">
                <Avatar :photo="user.avatar" :title="user.name" />
              </b-td>
              <b-td>
                <div class="rights-user-roles">
                  <b-dropdown variant="primary" text="Назначить роль">
                    <b-dropdown-item v-for="role in roles" :key="role.id" @click="addUserRole(user.id, role.id)">{{ role.title }}</b-dropdown-item>
                  </b-dropdown>
                  <ButtonRemove
                    v-for="roleId in user.roles"
                    :key="roleId"
                    :text="roles.find(r => r.id === roleId).title"
                    tooltipText="Удалить роль"
                    @click="removeUserRole(user.id, roleId)"
                  />
                </div>
              </b-td>
              <b-td>
                <div class="d-flex justify-content-end">
                  <div @click.prevent="removeUser(user.id)"><Remove tooltipText="Удалить сотрудника" /></div>
                </div>
              </b-td>
            </b-tr>
          </b-table-simple>
        </Block>
      </div>

      <div v-else-if="activeTab === 2">
        <Block title="Группы пользователей" noline>
          <b-table-simple class="rights_users_table mt-30">
            <b-tr v-for="group in groups" :key="group.id">
              <b-td width="21%">
                <div class="rights-user-name">{{ group.title }}</div>
              </b-td>
              <b-td>
                <div class="d-flex">
                  <Avatar :photo="user.avatar" :photoTitle="user.name" v-for="user in group.users" :key="user.id" />
                </div>
              </b-td>
            </b-tr>
          </b-table-simple>
        </Block>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import NotifyMixin from '@/mixins/NotifyMixin.js'
import TopPanel from '@/components/TopPanel'
import Switchbox from '@/components/ui/Switchbox'
import Remove from '@/components/ui/icons/TrashIcon'
import Alert from '@/components/ui/Alert'
import ButtonRemove from '@/components/ui/ButtonRemove'
import Avatar from '@/components/ui/Avatar'

export default {
  name: 'Rights',
  components: {
    TopPanel,
    Switchbox,
    Remove,
    Alert,
    ButtonRemove,
    Avatar
  },
  mixins: [NotifyMixin],
  data () {
    return {
      isLoading: true,
      error: '',
      activeTab: 1,
      newRoleTitle: '',
      selectedRole: null,
      selectedUser: null,
      searchQuery: '',
      roles: [],
      perms: [],
      users: [],
      groups: [],
      isRoleAdding: false,
      isUserRoleWarning: false
    }
  },
  async created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.isLoading = true

      try {
        const result = await this.$api.query('rights')
        this.roles = result.roles
        this.perms = result.perms
        this.selectedRole = null

        this.groups = result.groups
        this.users = result.users
        this.selectedUser = null
      } catch (e) {
        this.error = 'Произошла ошибка при получении данных'
      }

      this.isLoading = false
    },
    async togglePerm (roleId, permId) {
      const role = this.roles.find(el => el.id === roleId)
      const perms = role.perms

      if (!perms.includes(permId)) {
        perms.push(permId)
        Vue.set(this.roles.find(el => el.id === roleId), 'perms', perms)
      } else {
        Vue.delete(this.roles.find(el => el.id === roleId).perms, perms.indexOf(permId))
      }

      try {
        await this.$api.put('rights', {
          role: roleId,
          perm: permId
        })
        this.notifySuccess('Сохранение настроек', 'Настройки сохранены')
      } catch (e) {
        this.notifyError('Сохранение настроек', 'Ошибка сохранения')
      }
    },
    async addRole () {
      await this.$api.post('rights/role', {
        title: this.newRoleTitle
      })
      this.isRoleAdding = false
      await this.fetchData()
    },
    async addUser () {
      if (!this.selectedUser || !this.selectedRole) {
        return
      }
      const user = this.users.find(u => u.id === this.selectedUser)
      if (user && user.roles.find(r => r === this.selectedRole)) {
        this.isUserRoleWarning = true
        setTimeout(() => { this.isUserRoleWarning = false }, 3000)
        return
      }
      await this.$api.post('rights/user-role', {
        user: this.selectedUser,
        role: this.selectedRole
      })
      await this.fetchData()
    },
    async removeRole (roleId) {
      if (!confirm('Вы уверены?')) {
        return
      }
      try {
        await this.$api.delete('rights/role', { role: roleId })
        Vue.delete(this.roles, this.roles.findIndex(u => u.id === roleId))
      } catch (err) {
        this.notifyError('Не удалось удалить роль')
      }
    },
    async removeUser (userId) {
      if (!confirm('Вы уверены?')) {
        return
      }
      await this.$api.put('rights/user-role', { user: userId })
      Vue.delete(this.users, this.users.findIndex(u => u.id === userId))
    },
    async addUserRole (userId, roleId) {
      const userRoles = this.users.find(u => u.id === userId).roles
      if (userRoles.findIndex(el => el === roleId) !== -1) {
        return
      }
      userRoles.push(roleId)
      try {
        await this.$api.put('rights/user-role', { roles: userRoles, user: userId })
        this.notifySuccess('Сохранение настроек', 'Настройки сохранены')
      } catch (e) {
        this.notifyError('Сохранение настроек', 'Ошибка сохранения')
      }
    },
    async removeUserRole (userId, roleId) {
      const userRoles = this.users.find(u => u.id === userId).roles
      if (userRoles.length === 1 && !confirm('Вы точно хотите удалить последнюю роль?')) {
        return
      }
      try {
        await this.$api.delete('rights/user-role', {
          user: userId,
          role: roleId
        })
        Vue.delete(this.users.find(u => u.id === userId).roles, userRoles.findIndex(el => el === roleId))
        this.notifySuccess('Сохранение настроек', 'Настройки сохранены')
      } catch (e) {
        this.notifyError('Сохранение настроек', 'Ошибка сохранения')
      }
      await this.$store.dispatch('auth/checkAuth')
    },
    async toggleRoleRenaming (roleId) {
      const index = this.roles.findIndex(el => el.id === roleId)
      const isRenaming = !this.roles[index].isRenaming

      if (!this.roles[index].title) {
        return
      }

      if (!isRenaming) {
        await this.$api.put('rights/role/' + roleId, {
          title: this.roles[index].title
        })
      }

      Vue.set(this.roles[index], 'isRenaming', isRenaming)
    }
  }
}
</script>

<style>
.rights-tabs {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  padding: 0 25px;
}
.rights-tabs > div {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-color);
  padding: 15px 0;
  margin: 0 15px;
  cursor: pointer;
  border-bottom: 2px solid #ffffff;
}
.rights-tabs > div.active {
  color: var(--title-color);
  border-bottom-color: var(--title-color);
}
.rights-new-user {
  margin-top: 25px;
}
.rights-new-user .form-group {
  margin-right: 40px;
  width: 330px;
  height: 66px;
}
.rights-new-user .form-group > div {
  width: 100%;
}
.rights-new-user .vs__open-indicator {
  color: var(--text-color);
}
.rights-new-user .alert {
  margin-top: 25px;
  margin-left: 40px;
}
#rights_table {
  margin-top: 20px;
}
#rights_table tr td,
#rights_table tr th {
  text-align: center;
  vertical-align: middle;
}
#rights_table tr td:first-child,
#rights_table tr th:first-child {
  text-align: left;
}
#rights_table tr:first-child th {
  padding: 0;
  vertical-align: top;
}
#rights_table tr:first-child th:first-child {
  padding-top: 10px;
}
#rights_table tr:not(.parent) td:first-child {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--title-color);
  padding: 14px 0 18px;
}
#rights_table tr.parent td:first-child {
  padding: 9px 0 11px 8px;
  font-size: 12px;
  line-height: 16px;
  color: var(--title-color);
}
#rights_table thead th {
  border: none;
}
#rights_table td {
  border: none;
}
#rights_table tr.parent td {
  background-color: var(--bg-color);
  border: none;
}
#rights_table .role-title-wrap {
  display: inline-block;
  border-radius: 100px;
  background-color: var(--bg-color);
  padding: 7px;
  padding-left: 15px;
}
#rights_table .role-title-wrap-nodropdown {
  padding-right: 15px;
}
#rights_table .role-title {
  display: flex;
  align-items: center;
}
#rights_table .role-title span {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: var(--title-color);
}
.rights_users_table {
  margin-top: 35px;
}
.rights_users_table td {
  padding: 13px 0;
  vertical-align: middle;
  border: none;
  border-top: 1px solid var(--border-color);
}
#rights_table .btn-role-dropdown {
  display: block;
  background-color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 0;
  margin-left: 10px;
}
#rights_table .role-users {
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
}
#rights_table a:not(.dropdown-item) {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  border-bottom: 1px dashed;
}
.role-users-dropdown {
  width: 360px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(185, 195, 216, 0.5);
  border-radius: 5px;
  padding: 25px;
  padding-bottom: 15px;
  border: none;
}
.role-users-dropdown .popover-body {
  padding: 0;
}
#rights_table .role-title-rename input[type="text"] {
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-color);
  padding: 0;
  height: 22px;
}
.rights-search-users {
  padding-bottom: 30px;
}
.rights-search-users input {
  background-image: url('../../assets/svg/search_icon.svg');
  background-size: 14px 15px;
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 35px;
}
.rights-user-roles {
  display: flex;
}
</style>
